@import '../index.scss';
@import '../tools/functions.scss';

.copyright {
  padding: 24px;
  padding-bottom: 4rem;

  &.small-padding {
    padding-top: .5rem;
    padding-bottom: 3rem;
  }

  box-sizing: border-box;

  .github-links {
    display: grid;
    grid-template-columns: auto auto auto;
    row-gap: 10px;
    align-items: center;
    justify-content: center;
    gap: 3rem;
    margin-top: 1rem;
  
    @include min_mobile {
      grid-template-columns: auto;
      gap: 1rem;
    }
  }
  
  .github-container {
    display: flex;
    align-items: center;
  }
  
  .github-img {
    display: block;
    height: 24px;
    
    .dark & {
      filter: invert(1);
    }
  }
  
  .github-text {
    font-weight: bold;
    margin-left: 7px;
  }
  
  .twitter-bird {
    height: auto;
    width: 1.5rem;
    vertical-align: middle;
    margin-right: 0.3rem;
    margin-bottom: 0.1rem;
  }
  
  .main-twitter-link {
    margin-bottom: 1rem !important;
    font-weight: 500 !important;
    font-size: 1.2rem !important;
  }
  
  .twitter-link {
    color: rgb(53, 53, 53) !important;
  
    .dark & {
      color: rgb(223, 223, 223) !important;
    }
  }

  .version {
    margin-top: .5rem;
    color: lighten($color: #000000, $amount: 25);
    font-style: italic;
    text-align: right;
    position: relative;
  
    .dark & {
      color: lighten($color: #000000, $amount: 75);
    }
  }
}

.linear-header-text {
  @extend .background-flat-deg-image-linear;

  display: flex;
  width: 100%;
  padding: 2rem 5vw 2rem 5vw;
  box-sizing: border-box;
  min-height: 130px;

  & h2 {
    width: 100%;
    font-weight: 700;
    color: white;
    font-size: 5vw;

    overflow: hidden;
    text-overflow: ellipsis;
    display: -webkit-box;
    -webkit-line-clamp: 3;
    -webkit-box-orient: vertical;
    
    @media screen and (min-width: 1000px) {
      -webkit-line-clamp: 1;
    }
    @media screen and (min-width: 1200px) {
      font-size: 4rem;
    }

    letter-spacing: -.15rem;

    @include threshold_bar {
      justify-content: center;
      font-size: 50px;
    }
  }
}

.linear-header-divider {
  width: 100%;
  min-height: 1rem;
}
