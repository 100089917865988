$bottom_bar_height: 56px;

@mixin min_w_1000 {
  @media screen and (max-width: 1000px) {
    @content;
  }
}

@mixin mobile {
  @media screen and (max-width: 900px) {
    @content;
  }
}

@mixin mobile_large {
  @media screen and (max-width: 1100px) {
    @content;
  }
}

@mixin min_mobile {
  @media screen and (max-width: 500px) {
    @content;
  }
}

@mixin threshold_bar {
  @media screen and (max-width: 700px) {
    @content;
  }
}

