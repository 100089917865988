@import './tools/functions.scss';

body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  background-color: #f7f7f7;
  transition: background-color .25s;

  &.dark {
    background-color: #2b2b2b;
    color: #fff;
  }
}

.transparent-bg-text {
  background-color: #fff;
  background-size: cover;
  background-clip: text;
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
}

.background-image-linear {
  background-image: linear-gradient(220deg, #08ccc3 0%, #0078D7 100%);
  .dark & {
    background-image: linear-gradient(220deg, #08ccc3 0%, #6bbdff 100%);
  }
}

.dark-background-image-linear {
  background-image: linear-gradient(220deg, #0ac4c4 0%, #0080d5 100%);
  .dark & {
    background-image: linear-gradient(220deg, #0f9e99 0%, #13568e 100%);
  }
}

.background-text-linear {
  @extend .background-image-linear;
  @extend .transparent-bg-text;
}

.background-tiny-image-linear {
  background-image: linear-gradient(300deg, #08ccc3 85%, #0078D7 100%);
  .dark & {
    background-image: linear-gradient(300deg, #08ccc3 85%, #6bbdff 100%);
  }

  @include threshold_bar {
    background-image: linear-gradient(300deg, #08ccc3 50vw, #0078D7 100%);
    .dark & {
      background-image: linear-gradient(300deg, #08ccc3 50vw, #6bbdff 100%);
    }
  }
}

.background-flat-image-linear {
  background-image: linear-gradient(300deg, #08ccc3 0%, #0078D7 100%);
  .dark &:not(.no-dark) {
    background-image: linear-gradient(300deg, #08ccc3 0%, #6bbdff 100%);
  }
}

.background-flat-deg-image-linear {
  background-image: linear-gradient(270deg, #08ccc3 0%, #0078D7 100%);
  .dark &:not(.no-dark) {
    background-image: linear-gradient(270deg, #08ccc3 0%, #6bbdff 100%);
  }
}

.background-tiny-text-linear {
  @extend .background-tiny-image-linear;
  @extend .transparent-bg-text;
}

.background-error-linear {
  background-image: linear-gradient(310deg, #ce2b20 0%, #ff9218 100%);
}

.background-text-error-linear {
  @extend .background-error-linear;
  @extend .transparent-bg-text;
}

.icon-error-color {
  color: #f3430ddb;
}

.small-blue-divider {
  display: block;
  height: .375rem;
  margin-bottom: 1.5rem;
  width: 4.5rem;
  background-color: #1da1f2;

  .dark & {
    background-color: #3391cc;
  }

  @include threshold_bar {
    display: none;
  }
}

.small-classic-divider {
  @extend .small-blue-divider;
  
  background-color: #64c3ff;
  .dark & {
    background-color: #70b7e4;
  }
}

a {
  text-decoration: none;
}

a:hover {
  text-decoration: none;
}

a:active {
  color: inherit;
}

.dark {
  a {
    color: #cecdff;
  }

  a:active {
    color: #eeeeff !important;
  }

  a:visited {
    color: #bdbcfd;
  }
}

.dark .nav.primary {
  a {
    color: #fff;
  }

  a.Mui-selected {
    color: #86caff;
  }
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

.divider-margin {
  margin: .75rem auto !important;
}

.pre-line {
  white-space: pre-line;
}

.pre-wrap {
  white-space: pre-wrap;
}

.break-all {
  word-break: break-all;
}

.break-word {
  overflow-wrap: break-word;
  // Deprecated but needed, overflow-wrap does not work properly
  word-break: break-word;
}

.divider-big-margin {
  margin: 1.2rem auto !important;
}

.bold {
  font-weight: bold !important;
}

.no-bold {
  font-weight: normal !important;
}

.italic {
  font-style: italic;
}

.center-space-between {
  display: flex;
  justify-content: space-between;
  width: 100%;
}

.center-absolute {
  position: absolute;
  top: 40%;
  left: 50%;
  transform: translate(-50%, -50%);
}
